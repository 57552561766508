import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import axios from 'axios';
import logo from '../logo192.png'; // Update logo path as needed
import CampaignProgress from './campaignProgress.js';

const CampaignLiveAdmin = ({ campaign }) => {
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${Config.API_URL}/api/product`, {
                    params: { campaignName: campaign.campaignName } // Send campaignName as parameter
                });
                setProduct(response.data); // Update state with fetched data
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        fetchProduct();
    }, [campaign.campaignName]);
    const title = {
        title: "Subtitle"
    };
    // const brandName = {
    //     brandName: "BrandName"
    // }

    // const followersCriteria = {
    //     followersCriteria: "followersCriteria"
    // }
    // const deliverables = {
    //     deliverables: "deliverables"
    // }
    // const GiveProductsWorth = {
    //     GiveProductWorth: "Give Products Worth"
    // }
    // const dealtype = {
    //     dealtype: "deal type"
    // }
    // Styles for the background card
    const topImageStyle = {
        backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(campaign.uploadBrandLogo)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '180px', // Adjust height as needed
        width: '450px', // Full width to match the button card below
        borderRadius: '6px',
        marginBottom: '-10px',
        marginLeft: '-15px',
        marginTop: '-13px',

    };
    const logoStyle = {
        position: 'absolute',
        bottom: '4px',
        left: '55px',
        width: '40px',
        height: '36px',
        top: "220px"
    };
    const { brandName, followersCriteria, infDeliverables, cashValue, campaignName, dealtype, uploadBrandLogo, approve, rejected } = campaign;

    return (
        <div className="container" style={{ position: "relative" }}>
            <div className="card-content">
                <div className="box mt-2 ml-2 " style={{ width: 475, height: 580, }}>
                    <div style={topImageStyle}>
                        <img src={logo} alt="Logo" style={logoStyle} />

                    </div>
                    <br />
                    <div>
                        {/* Brand Name and Followers Criteria Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "30px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
                                <p className='has-text-weight-bold'>{brandName}</p>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <span className="icon" style={{ marginRight: "125px" }}>
                                    <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                </span>
                                <p className="has-text-grey-light mr-5" >Followers Criteria</p>
                                <p className='has-text-weight-bold mr-5'>{followersCriteria}</p>
                            </div>
                        </div>

                        {/* deliverables and Give Products Worth Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "30px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
                                <p className='has-text-weight-bold'>{infDeliverables}</p>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <span className="icon" style={{ marginRight: "130px" }}>
                                    <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
                                <p className='has-text-weight-bold mr-1'>{cashValue}</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "30px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deal Type</p>
                                <p className='has-text-weight-bold'>{dealtype}</p>
                            </div>
                        </div>

                        {/* Dynamic Status */}

                        <CampaignProgress campaign={campaign} />
                        <div style={{ flexDirection: 'row', gap: '10px', marginTop: '25px' }}>
                            <button onClick={() => navigate(`/view-campaignBrand/${campaign.campaignName}`)} className="button is-small-custom has-text-info mr-3" style={{ width: '210px' }}>
                                View Campaign
                            </button>
                            <button onClick={() => navigate(`/tbladmin/${campaign.campaignName}`)} className="button is-small-custom has-text-info" style={{ width: '210px' }}>
                                View More
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CampaignLiveAdmin;
